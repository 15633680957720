import { Amplify, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import awsExports from './aws-exports';

Amplify.configure(awsExports);

async function signOut() {
    try {
        await Auth.signOut({ global: true });
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

function WithMidwayAuth(App) {
  return function Holding() {
    const [user, setUser] = useState();

    useEffect(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          setUser(user);
        })
        .catch(() => {
            console.info('Federated login...');
            Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        });
    }, []);

    return !user ? (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
            <p className="a-popover-loading"></p>
      </div>
    ) : (
      <App user={user} signOut={signOut} />
    );
  };
}

export default WithMidwayAuth;